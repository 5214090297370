import axiosInstance from '@/services/axios'

export default {
  namespaced: true,

  state: {
    isLoading: false,
    bots: {

    },
    plans: {
      data: [],
      count: null,
      bio: null,
      profilePicture: null,
      name: null,
      username: null,
      userInfo: null
    },
    orders: {
      isLoading: false,
      orderInfo: {

      }
    },
    user: {},
    stripe: {
      isLoading: false,
      account: null,
      charges_enabled: false,
      payouts_enabled: false,
      balance: {
        isLoading: false,
        balanceInfo: {

        }
      },
      orders: {
        isLoading: false,
        orderInfo: {

        }
      }
    },
  },
    // GETTERS
  getters: {
    isLoading (state) {
      return state.isLoading
    },
    plans (state) {
      return state.plans
    },
    plan (state) {
      return state.plan
    },
    user (state) {
      return state.user
    },
    stripe (state) {
      return state.stripe
    },
    bot (state) {
      return state.bot
    }
  },
  actions: {
    getBots ({commit}) {
      return axiosInstance.get('/api/v1/bots/get')
        .then(res => {
          const stats = res.data
          this.state.stats.bots = stats;
          commit('setStats', stats)
          return stats
        })
    },
    getListing ({ commit }, payload) {
      commit('loadingState', { isLoading: true })
      setTimeout(() => {
        commit('getListing', payload)
        commit('loadingState', { isLoading: false })
      }, 1000)
    },
    fetchUserStats ({commit}) {
      return axiosInstance.get('/api/v1/users/me/activity')
        .then(res => {
          const stats = res.data
          this.state.stats.plans = stats;
          commit('setStats', stats)
          return stats
        })
    },
    fetchStripeStats ({commit}) {
      commit('stripeLoadingState', { isLoading: true })
      return axiosInstance.get('/api/v1/users/me/stripe')
        .then(res => {
          const stats = JSON.parse(JSON.stringify(res.data.account))
          this.state.stats.stripe.account = stats;
          commit('stripeLoadingState', { isLoading: false })
          commit('stripePayoutAndChargesState', { account: stats })
          commit('setStats', stats)
          return stats
        })
    },

    fetchStripeBalanceStats ({commit}) {
      commit('stripeBalanceLoadingState', { isLoading: true })
      return axiosInstance.get('/api/v1/users/me/stripebalance')
        .then(res => {
          const stats = JSON.parse(JSON.stringify(res.data.balance))
          this.state.stats.stripe.balance.balanceInfo = stats;
          commit('stripeBalanceLoadingState', { isLoading: false })
          commit('setStats', stats)
          return stats
        })
    },

    fetchStripeOrderStats ({commit}) {
      commit('stripeOrdersLoadingState', { isLoading: true })
      return axiosInstance.get('/api/v1/users/me/orders')
        .then(res => {
          const stats = JSON.parse(JSON.stringify(res.data.orders))
          this.state.stats.stripe.orders.orderInfo = stats;
          commit('stripeOrdersLoadingState', { isLoading: false })
          commit('setStats', stats)
          return stats
        })
    },

    fetchOrderStats ({commit}) {
      commit('ordersLoadingState', { isLoading: true })
      return axiosInstance.get('/api/v1/users/me/orders')
        .then(res => {
          const stats = JSON.parse(JSON.stringify(res.data.orders))
          this.state.stats.orders.orderInfo = stats.sort(function(a,b){
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return Date.parse(b.createdAt) - Date.parse(a.createdAt);
          });
          commit('ordersLoadingState', { isLoading: false })
          commit('setStats', stats)
          return stats
        })
    },

    fetchUsernameStats ({commit}, username) {
      return axiosInstance.get(`/api/v1/users/${username}/activity`)
        .then(res => {
          const stats = res.data
          commit('setStats', stats)
          return stats
        })
      },
    fetchPlanStats ({commit},url) {
      commit('loadingState', { isLoading: true })
      return axiosInstance.get(`/api/v1/plans/${url}`)
        .then(res => {
          const stats = res.data
          this.state.stats.plans = stats;
          commit('setStats', stats)
          commit('loadingState', { isLoading: false })
          return stats
        })
      },

    fetchPlansStats ({commit}) {
      return axiosInstance.get(`/api/v1/plans/`)
        .then(res => {
          const stats = res.data
          this.state.stats.plans = stats;
          commit('setStats', stats)
          return stats
        })
      }
  },
  mutations: {
    setStats (state, stats) {
      return Object.assign(state, {}, stats)
    },
    loadingState (state, { isLoading }) {
      state.isLoading = isLoading
    },
    stripeLoadingState (state, { isLoading }) {
      state.stripe.isLoading = isLoading
    },
    stripeBalanceLoadingState (state, { isLoading }) {
      state.stripe.balance.isLoading = isLoading
    },
    stripeOrdersLoadingState (state, { isLoading }) {
      state.stripe.orders.isLoading = isLoading
    },
    ordersLoadingState (state, { isLoading }) {
      state.orders.isLoading = isLoading
    },
    stripePayoutAndChargesState (state, { account }) {
      if (account != null) {
        state.stripe.charges_enabled = account.charges_enabled;
        state.stripe.payouts_enabled = account.payouts_enabled;
      } else {
        state.stripe.charges_enabled = false
        state.stripe.payouts_enabled = false
      }

    },
    getPlan (state, { url }) {
      if (state.plans.length > 0) {
        state.plan = state.plans.find(plan => plan.url === url)
      }
    },
  }
}
